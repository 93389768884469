@import './global.scss';
.over{
    width: 4em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.rank {
    overflow: hidden;

    .mainTitle {
        height: 72px;
        line-height: 72px;
        color: $white;
        overflow: hidden;
        .progress {
            height: 48px;
            line-height: 16px;
            float: right;
            text-align: right;
            width: 50%;
            margin-top: 17px;
            .currentRank {
                font-size: 14px;
                color: $gold;
            }
            .progressLine {
                width: 86px;
                height: 3px;
                background: #ccc;
                position: relative;
                float: right;
                margin: 4px;
                span {
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 70%;
                    height: 3px;
                    background: $pink;
                }
                &.top1 span {
                    width: 100%;
                }
            }
            .gap {
                clear: both;
                float: right;
                font-size: 12px;
                color: $pink;
            }
        }
    }

    .rankHint {
        color: #fb409b;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .box {
        width: 100%;
        padding: 0 18px 0 18px;
        overflow: hidden;
        background: linear-gradient(180deg, #211c1e 2%, #100b10 100%);
        box-shadow: -3px -3px 0px 0px #293036, 4px 4px 0px 0px #0e0d10;
        border: 3px solid;
        border-image: linear-gradient(
                180deg,
                rgba(112, 123, 129, 1),
                rgba(23, 31, 42, 1)
            )
            3 3;
    }
    .singleRank {
        display: flex;
        justify-content: space-between;
        color: $white;
        font-size: 14px;
        height: 44px;
        line-height: 44px;
        &.bottomBorder {
            border-bottom: 1px solid #707b81;
        }
        span {
            display: inline-block;
            overflow: hidden;
            text-align: center;
            &:first-child {
                width: 28px;
            }
            &:nth-child(2) {
                width: 56px;
            }
            &:last-child {
                width: 84px;
            }
        }
        .top1 {
            height: 30px;
            width: 30px;
            margin-top: 7px;
            background: url('../images/top1.png') 100% / cover;
        }
        .top2 {
            @extend .top1;
            background-image: url('../images/top2.png');
        }
        .top3 {
            @extend .top1;
            background-image: url('../images/top3.png');
        }
    }
    .title {
        height: 58px;
        line-height: 58px;
        border-bottom: 1px solid #707b81;
        font-size: 13px;
        span {
            text-align: center;
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: right;
            }
        }
    }
    .content {
        width: 50%;
        float: left;
        margin-bottom: 10px;
        font-size: 14px;
    }
    .top20 {
        width: 100%;
        overflow: hidden;
        .item {
            margin-top: 20px;
        }
        .hint {
            margin-top: 54px;
        }
    }
    .hint {
        color: $white;
        font-size: 13px;
        float: right;
        width: 50%;
        text-align: right;
    }
    .left {
        color: $white;
    }

    .right {
        color: $pink;
        float: right;
    }

    .btn {
        background: url('../images/home_btn_bg.png') 100% / contain;
        color: $gold;
        width: 67px;
        height: 26px;
        font-size: 13px;
        margin: 10px auto 15px auto;
        display: block;
    }
}

