@import './global.scss';

.tables {
    padding-bottom: 18px;
    background: $black url('../images/bg.png') no-repeat top center;
    min-height: 100vh;
    position: relative;
}

.header {
    height: 44px;
    line-height: 46px;
    background: $white;
    text-align: center;
    span {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 50px;
        cursor: pointer;
        font-size: 13px;
        padding-left: 30px;
        &::before {
            content: '';
            background: url('../images/back.png') 100% / contain;
            height: 18px;
            width: 11px;
            top: 13px;
            left: 13px;
            position: absolute;
            display: block;
        }
    }
}

.tab {
    background: $white;
    height: 50px;
    line-height: 50px;
    margin-top: -1px;
    span {
        font-size: 13px;
        margin-left: 16px;
        cursor: pointer;
        &.active {
            color: $pink;
            font-weight: 600;
        }
    }
}

.numbers {
    margin: 10px;
}

.container {
    overflow: hidden;
    padding: 3%;
}

.item {
    width: 48%;
    padding-top: 10px;
    float: left;
    &:nth-child(2n) {
        float: right;
    }
}
.loadingWarp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.loadMoreBtn {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 4px;
    display: inline-block;
    margin: 0 auto;
    font-size: 14px;
    border: 1px solid #eee;
}
