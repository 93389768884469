@import './global.scss';

.pickNumber {
    background-color: $black;
    min-height: 100vh;
    position: relative;
}

.bg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
}

.container {
    background-color: $white;
    padding: 20px 10px 5px 10px;
    width: 94%;
    margin: 0 auto;
    border-radius: 8px;
    position: absolute;
    left: 3%;
    bottom: 20px;
}

.title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
    span {
        display: inline-block;
        background: $lightPink;
        color: $pink;
        font-size: 13px;
        border-radius: 10px;
        padding: 3px 6px;
        position: absolute;
        right: 2px;
        top: 0;
    }
}

.subtitle {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 600;
    span {
        color: $pink;
        margin-left: 16px;
    }
}

.hint {
    font-size: 15px;
    span {
        color: $pink;
        background-color: rgba(247, 71, 158, 0.1);
        font-size: 12px;
        padding: 4px;
        margin-left: 16px;
        display: inline-block;
        border-radius: 10px;
    }
}

.position {
    height: 140px;
    width: 140px;
    margin: 10px auto 4px auto;
    background: url('../images/pick_bg.png') no-repeat 100% / cover;
    position: relative;
    .item {
        height: 40px;
        width: 40px;
        display: inline-block;
        position: absolute;
        &.picked::after {
            content: '';
            display: inline-block;
            position: absolute;
            height: 40px;
            width: 40px;
            border: 2px solid $gold;
            border-radius: 50%;
        }
    }
    .jin {
        @extend .item;
        background: url('../images/pick_jin_active.png') no-repeat 100% / cover;
        top: 0;
        left: calc(50% - 20px);
        &.disabled {
            background: url('../images/pick_jin.png') no-repeat 100% / cover;
        }
    }
    .mu {
        @extend .item;
        background: url('../images/pick_mu_active.png') no-repeat 100% / cover;
        top: 44px;
        right: -6px;
        &.disabled {
            background: url('../images/pick_mu.png') no-repeat 100% / cover;
        }
    }
    .shui {
        @extend .item;
        background: url('../images/pick_shui_active.png') no-repeat 100% / cover;
        bottom: 0;
        right: 12px;
        &.disabled {
            background: url('../images/pick_shui.png') no-repeat 100% / cover;
        }
    }
    .huo {
        @extend .item;
        background: url('../images/pick_huo_active.png') no-repeat 100% / cover;
        bottom: 0;
        left: 12px;
        &.disabled {
            background: url('../images/pick_huo.png') no-repeat 100% / cover;
        }
    }
    .tu {
        @extend .item;
        background: url('../images/pick_tu_active.png') no-repeat 100% / cover;
        top: 44px;
        left: -6px;
        &.disabled {
            background: url('../images/pick_tu.png') no-repeat 100% / cover;
        }
    }
}

.allNumbers {
    margin-top: 18px;
    span {
        display: inline-block;
        width: 32%;
        text-align: center;
        height: 42px;
        line-height: 42px;
        background-color: #fceaf1;
        color: #f7479e;
        margin-right: 2%;
        margin-bottom: 6px;
        border-radius: 3.6px;
        position: relative;
        &:nth-child(3n) {
            margin: 0;
        }
        &:nth-child(10n) {
            margin-left: 34%;
        }
        &.disabled {
            background: rgba(0, 0, 0, 0.1);
            color: #fceaf1;
        }
        &.picked::after {
            content: '';
            display: inline-block;
            height: 42px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid #f7479e;
            border-radius: 4px;
        }
    }
}
